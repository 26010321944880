import React, { useLayoutEffect } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import Layout from "../layout/Index";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import HomePage from "../pages/HomePage";
const queryClient = new QueryClient();

const Pages = () => {
  const location = useLocation();
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);
  return (
    <QueryClientProvider client={queryClient}>
      <Routes>
        <Route path={`${process.env.PUBLIC_URL}`} element={<Layout />}>
          <Route index element={<HomePage />}></Route>
        </Route>
      </Routes>
    </QueryClientProvider>
  );
};
export default Pages;
