import React, { useState, useEffect } from "react";
import Icon from "../../../../components/icon/Icon";
import { DropdownToggle, DropdownMenu, Dropdown, Button } from "reactstrap";
import { useThemeUpdate } from "../../../provider/Theme";
import { getCookie } from "../../../../utils/Utils";
import InfiniteScroll from "react-infinite-scroll-component";
const NotificationItem = ({ icon, iconStyle, text, date, time, link, module, id }) => {
  const handleClick = async () => {
    try {
      const formData = new FormData();
      formData.append("notification_id", id);
      formData.append("status", "1");
      formData.append("module", module);

      const response = await fetch(`${process.env.REACT_APP_API_URL}api/v1/public/set-notification-status`, {
        method: "POST",
        headers: {
          authToken: getCookie("authToken", null),
        },
        body: formData,
      });

      const result = await response.json();

      if (result.status) {
        if (link) {
          const baseUrl =
            module === "2"
              ? process.env.REACT_APP_PUBLIC_URL_NOTIFICATION
              : process.env.REACT_APP_ACCOUNT_URL_NOTIFICATION;
          const url = `${baseUrl}${link}`;
          window.location.href = url;
        }
      } else {
        console.error("Failed to update notification status");
      }
    } catch (error) {
      console.error("An error occurred while updating notification status", error);
    }
  };

  return (
    <div className="nk-notification-item" key={id} id={id} onClick={handleClick}>
      <div className="nk-notification-icon">
        <Icon name={icon} className={[`icon-circle ${iconStyle ? " " + iconStyle : ""}`]} />
      </div>
      <div className="nk-notification-content">
        <div className="nk-notification-text">{text}</div>
        <div className="nk-notification-time">{date}</div>
      </div>
      <div className="" style={{ marginLeft: "auto" }}>
        <span className="fw-bold text-nowrap" style={{ fontSize: "10px" }}>
          {time}
        </span>
      </div>
    </div>
  );
};

const Notification = () => {
  const themeUpdate = useThemeUpdate();
  const [open, setOpen] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [notificationsCount, setNotificationsCount] = useState(0);
  const [offset, setOffset] = useState(0);
  const limit = 10;
  const toggle = () => {
    themeUpdate.sidebarHide();
    setOpen((prevState) => !prevState);
  };
  const fetchNotifications = async () => {
    try {
      const formData = new FormData();
      formData.append("limit", limit);
      formData.append("offset", offset);
      formData.append("multiple_module", "1,2");
      formData.append("recent", 10);
      const response = await fetch(`${process.env.REACT_APP_API_URL}api/v1/public/get-notifications`, {
        method: "POST",
        headers: {
          authToken: getCookie("authToken", null),
        },
        body: formData,
      });

      const result = await response.json();

      if (result.status) {
        const formattedNotifications = result.data.notifications.map((notification) => {
          const createdAt = new Date(notification.created_at);
          const date = createdAt.toLocaleDateString("en-US", {
            day: "2-digit",
            month: "short",
          });
          const time = createdAt.toLocaleTimeString("en-US", {
            hour: "2-digit",
            minute: "2-digit",
            hour12: true,
          });

          return {
            id: notification.id,
            icon: "curve-down-right",
            iconStyle: notification.seen === "0" ? "bg-danger-dim" : "bg-success-dim",
            text: notification.description,
            date: date,
            time: time,
            link: notification.link,
            module: notification.module,
          };
        });

        setNotifications((prevNotifications) => [...prevNotifications, ...formattedNotifications]);
        setNotificationsCount(result.data.total_count);
        setOffset((prevOffset) => prevOffset + limit);
      } else if (result.message === "No notifications yet") {
        setNotifications([]);
      } else {
        setError("Failed to fetch notifications");
      }
    } catch (error) {
      setError("An error occurred while fetching notifications");
    } finally {
      setLoading(false);
    }
  };

  const markAllAsRead = async (ev) => {
    ev.preventDefault();

    try {
      const formData = new FormData();
      formData.append("module", "2");
      formData.append("status", "1");

      const response = await fetch(`${process.env.REACT_APP_API_URL}api/v1/public/set-notification-status`, {
        method: "POST",
        headers: {
          authToken: getCookie("authToken", null),
        },
        body: formData,
      });

      const result = await response.json();

      if (result.status) {
        setOffset(0);
        setNotifications([]);
        fetchNotifications();
        setNotificationsCount(0);
      } else {
        setError("Failed to mark notifications as read");
      }
    } catch (error) {
      setError("An error occurred while marking notifications as read");
    }
  };

  useEffect(() => {
    fetchNotifications();
  }, []);

  const hasMore = notifications.length < notificationsCount;

  return (
    <Dropdown isOpen={open} className="user-dropdown" toggle={toggle}>
      <DropdownToggle tag="a" className="dropdown-toggle nk-quick-nav-icon px-0">
        <div className="">
          {notificationsCount > 0 && (
            <Button className="btn-round btn-icon notificationIcon" color="danger" size="xs">
              {notificationsCount}
            </Button>
          )}
          <Icon name="bell-fill" style={{ position: "relative", top: "3px" }} />
        </div>
      </DropdownToggle>
      <DropdownMenu end className="dropdown-menu-xl dropdown-menu-s1">
        <div className="dropdown-head">
          <span className="sub-title nk-dropdown-title">Notifications</span>
          <a onClick={markAllAsRead} className="pointer text-danger">
            Mark All as Read
          </a>
        </div>
        <div className="dropdown-body">
          <div className="nk-notification" id="nk-notification">
            {loading && (
              <div
                style={{
                  padding: "1.25rem",
                  textAlign: "center",
                  color: "rgb(225, 73, 84)",
                  fontWeight: 600,
                  textTransform: "capitalize",
                }}
              >
                Loading...
              </div>
            )}
            {!loading && !error && notifications.length === 0 && (
              <div
                style={{
                  padding: "1.25rem",
                  textAlign: "center",
                  color: "rgb(225, 73, 84)",
                  fontWeight: 600,
                  textTransform: "capitalize",
                }}
              >
                No notifications yet
              </div>
            )}
            {!loading && !error && notifications.length > 0 && (
              <InfiniteScroll
                dataLength={notifications.length}
                next={fetchNotifications}
                hasMore={hasMore}
                loader={
                  <div
                    style={{
                      padding: "1.25rem",
                      textAlign: "center",
                      color: "rgb(225, 73, 84)",
                      fontWeight: 600,
                      textTransform: "capitalize",
                    }}
                  >
                    Loading more notifications...
                  </div>
                }
                scrollableTarget="nk-notification"
              >
                {notifications.map((item) => (
                  <NotificationItem
                    key={item.id}
                    id={item.id}
                    icon={item.icon}
                    iconStyle={item.iconStyle}
                    text={item.text}
                    date={item.date}
                    time={item.time}
                    link={item.link}
                    module={item.module} // Pass module value here
                  />
                ))}
              </InfiniteScroll>
            )}
            {!loading && error && (
              <div
                style={{
                  padding: "1.25rem",
                  textAlign: "center",
                  color: "rgb(225, 73, 84)",
                  fontWeight: 600,
                  textTransform: "capitalize",
                }}
              >
                {error}
              </div>
            )}
          </div>
        </div>
      </DropdownMenu>
    </Dropdown>
  );
};

export default Notification;
