import React, { useState, useEffect } from "react";
import Head from "../layout/head/Head";
import { PolotnoContainer, SidePanelWrap, WorkspaceWrap } from "polotno";
import { Toolbar } from "polotno/toolbar/toolbar";
import { PagesTimeline } from "polotno/pages-timeline";
import { ZoomButtons } from "polotno/toolbar/zoom-buttons";
import { SidePanel } from "polotno/side-panel";
import { Workspace } from "polotno/canvas/workspace";
import "@blueprintjs/core/lib/css/blueprint.css";
import { createStore } from "polotno/model/store";

const HomePage = () => {
  const store = createStore({
    key: process.env.REACT_APP_POLONTO_SDK,
    showCredit: false,
  });
  const page = store.addPage();
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const adjustedWidth = windowSize.width - 17;
  const adjustedHeight = windowSize.height - 143;

  return (
    <React.Fragment>
      <Head title="Canvas" />

      <PolotnoContainer style={{ width: `${adjustedWidth}px`, height: `${adjustedHeight}px` }}>
        <SidePanelWrap>
          <SidePanel store={store} />
        </SidePanelWrap>
        <WorkspaceWrap>
          <Toolbar store={store} downloadButtonEnabled />
          <Workspace store={store} />
          <ZoomButtons store={store} />
          <PagesTimeline store={store} />
        </WorkspaceWrap>
      </PolotnoContainer>
    </React.Fragment>
  );
};

export default HomePage;
