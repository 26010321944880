import React, { createContext, useEffect, useState } from "react";
import { Outlet } from "react-router-dom";
import Head from "./head/Head";
import Header from "./header/Header";
import Footer from "./footer/Footer";
import AppRoot from "./global/AppRoot";
import AppMain from "./global/AppMain";
import AppWrap from "./global/AppWrap";
import { ToastContainer } from "react-toastify";
import Cookies from "js-cookie";
export const GloabalContext = createContext();
const Layout = ({ title, app, ...props }) => {
  const [files, setFiles] = useState([]);
  const [authChecking, setAuthChecking] = useState(true);
  useEffect(() => {
    const now = new Date();
    const expires = new Date(now.getTime() + 24 * 60 * 60 * 1000);
    Cookies.set("currentURL", window.location.href, {
      domain: process.env.REACT_APP_COOKIE_DOMAIN,
      expires: expires,
    });
  }, []);
  return (
    <>
      <GloabalContext.Provider value={{ files, setFiles }}>
        <Head title={!title && "Loading"} />
        <AppRoot className="npc-apps apps-only">
          <AppMain>
            <AppWrap>
              <Header fixed />
              <ToastContainer hideProgressBar autoClose={3000} />
              <Outlet />
              <Footer fixed />
            </AppWrap>
          </AppMain>
        </AppRoot>
      </GloabalContext.Provider>
    </>
  );
};
export default Layout;
